<template lang="pug">
  .root
    Base(:liveType="liveType")
    
</template>

<style src="./Event.scss" lang="scss" scoped>

</style>

<script>
import Base from './components/Base'
import {LIVE_TYPE} from './helper/def'

export default {
  name: "LiveEvent",
  beforeRouteLeave(to, from, next) {
    if(this.isStarted){
      const answer = window.confirm("配信が終了していません。移動してもよろしいですか？");
      if(answer){
        next()
      }else {
        next(false)
      }
    } else {
      next();
    }
  },
  components: {
    Base
  },
  data() {
    return {
      liveType: LIVE_TYPE.EVENT
    }
  },
  props: {},
  computed: {
    isStarted(){
      return this.$store.getters.enum_list.live_status_list.ONGOING == this.$store.state.live.info.status || this.$store.getters.enum_list.live_status_list.PREPARATION == this.$store.state.live.info.status;
    },
  },
  watch: {},
  mounted() {},
  async created() {},
  updated() {},
  async destroyed() {},
  methods: {}
};
</script>
